/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Poppins';
  font-style: normal;
}

.custom-modal .modal-dialog {

  top: -35%;
  /* Adjust this value as needed */

}




.modal-confirm .icon-box {
  color: #fff;
  position: absolute;
  margin: 50;
  left: 0;
  right: 0;
  top: -43px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  z-index: 9;
  background: #db111f;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.modal-confirm {
  color: #636363;
  width: 325px;
  font-size: 14px;
}

.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
}

.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
}

.modal-confirm h4 {
  text-align: center;
  font-size: 21px;
  margin: 0px 0 -15px;
}

.modal-confirm .form-control,
.modal-confirm .btn {
  min-height: 40px;
  border-radius: 3px;
}

.modal-footer {
  display: flex;
  justify-content: center;
}

.modal-confirm .close {
  position: absolute;
  top: -5px;
  right: -5px;
}

.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
}

.modal-confirm .icon-box {
  color: #fff;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -43px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  z-index: 9;
  background: #82ce34;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.modal-confirm .icon-box i {
  font-size: 21px;
  position: relative;
  top: 3px;
}

.modal-confirm.modal-dialog {
  margin-top: 80px;
}

.modal-confirm .btn {
  color: #fff;
  border-radius: 4px;
  background: #82ce34;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
}

.modal-confirm .btn:hover,
.modal-confirm .btn:focus {
  background: #6fb32b;
  outline: none;
}

.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

#myModal_eror .modal-confirm .icon-box {
  background: red;
}

#myModal_eror .modal-confirm .btn {
  background: red;
}

#myModal_eror .modal-confirm .btn:hover,
#myModal_eror .modal-confirm .btn:focus {
  background: red;
}

#myModal_succes .modal-body,
#myModal_eror .modal-body {
  padding: 0;
}

#myModal_succes .modal-footer,
#myModal_eror .modal-footer {
  padding: 0;
}

*:not(span):not(mat-icon) {
  font-family: 'Poppins' !important;
  font-style: normal;
}

.text_success {
  color: #43BC25 !important;
}

.main_heading {
  font-size: 22px !important;
  line-height: 1.1 !important;
}

.sub_heading {
  font-size: 14px !important;
  line-height: 0.9 !important;
}

@media screen and (min-width: 576px) {

  /* Small devices (phones) */
  .main_heading {
    font-size: 28px !important;
    line-height: 1 !important;
  }

  .sub_heading {
    font-size: 20px !important;
    line-height: 1 !important;
  }
}

@media screen and (min-width: 768px) {

  /* Medium devices (tablets) */
  .main_heading {
    font-size: 34px !important;
    line-height: 1.1 !important;
  }

  .sub_heading {
    font-size: 26px !important;
    line-height: 1 !important;
  }
}

@media screen and (min-width: 992px) {

  /* Large devices (desktops) */
  .main_heading {
    font-size: 40px !important;
    line-height: 1 !important;
  }

  .sub_heading {
    font-size: 30px !important;
    line-height: 1 !important;
  }
}

@media screen and (min-width: 1200px) {

  /* Extra large screens */
  .main_heading {
    font-size: 48px !important;
    line-height: 1 !important;
  }

  .sub_heading {
    font-size: 34px;
    line-height: 1;
  }
}

@media screen and (min-width: 1400px) {

  /* Extra extra large screens */
  .main_heading {
    font-size: 50px !important;
    line-height: 1.1 !important;
  }

  .sub_heading {
    font-size: 36px !important;
    line-height: 1 !important;
  }
}

//animation
.anime-container {
  overflow: hidden;
  position: relative;
}

.left-side-animation,
.top-side-animation,
.bottom-side-animation,
.right-side-animation {
  display: inline-block;
  opacity: 0;
}

.left-side-animation.show {
  transform: translateX(-30%);
  /* Left animation default */
  animation: slideInLeft 1.5s ease-in-out forwards;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-30%);
    /* Start from inside but hidden */
  }

  85% {
    transform: translateX(2%);
  }

  100% {
    transform: translateX(0);
    /* Fully visible inside */
    opacity: 1;

  }
}

.top-side-animation.show {
  transform: translateY(-100%);
  /* Left animation default */
  animation: slideInBottom 1.5s ease-in-out forwards;
}

@keyframes slideInBottom {
  0% {
    transform: translateY(-100%);
    /* Start from inside but hidden */

  }

  85% {
    transform: translateY(3%);
  }

  100% {
    transform: translateY(0);
    /* Fully visible inside */
    opacity: 1
  }
}


.bottom-side-animation.show {
  transform: translateY(-100%);
  /* Left animation default */
  animation: slideInTop 1.5s ease-in-out forwards;
}

@keyframes slideInTop {
  0% {
    transform: translateY(100%);
    /* Start from inside but hidden */

  }

  85% {
    transform: translateY(-3%);
  }

  100% {
    transform: translateY(0);
    /* Fully visible inside */
    opacity: 1
  }
}

.right-side-animation.show {
  transform: translateY(-100%);
  /* Left animation default */
  animation: slideInRight 1.5s ease-in-out forwards;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    /* Start from inside but hidden */

  }
  85% {
    transform: translateX(-3%);
  }

  100% {
    transform: translateX(0);
    /* Fully visible inside */
    opacity: 1
  }
}

.image_animation {
  display: inline-block;
  transform: scale(0);
  /* Start zoomed out */
  transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out;
  transform-origin: center center;
  /* Ensure the zoom is from the center */
}

.image_animation.show {
  animation: imgAnimation 1.5s ease-in-out forwards;
}

@keyframes imgAnimation {
  0% {
    transform: scale(0.5);
    /* Start from inside but hidden */
    opacity: 0;
  }

  85% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    /* Fully visible inside */
    opacity: 1;
  }
}